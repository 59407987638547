import React from 'react'
import RankingCard from './components/RankingCard'

const RankingCards = ({ websData = [] }) => {
  return (
    <div className="mx-auto pt-4">
      <div className="grid lg:grid-cols-3 gap-4">
        {websData.map(web => (
          <RankingCard key={web.category} web={web} />
        ))}
      </div>
    </div>
  )
}

export default RankingCards
