import React, { useState, useEffect } from 'react'
import Layout from '../components/shared/components/Layout'
import SearchBar from '../components/shared/components/SearchBar'
import RankingCards from '../components/RankingsPage/RankingCards'
import websData from '../data/ranking-webs-data'
import SEO from '../components/Seo'

const ranking = () => {
  const [websDataState, setWebsDataState] = useState(websData)

  useEffect(() => {
    setWebsDataState(websData)
  }, [websData])

  const handleChangeSearchBar = e => {
    const dataCopy = [...websDataState]
    let searchList = []

    if (e.target.value !== '') {
      searchList = dataCopy.filter(item => {
        const itemTitle = item.category.toLowerCase()
        const searchText = e.target.value.toLowerCase().trim()
        return itemTitle.includes(searchText)
      })
    } else {
      searchList = [...websData]
    }
    setWebsDataState(searchList)
  }

  return (
    <Layout>
      <SEO title="Ranking Page" />
      <div className="container mx-auto flex flex-col px-8 py-8">
        <h1 className="mb-1">Web Performance Ranking</h1>
        <SearchBar handleChangeSearchBar={handleChangeSearchBar} />
        <RankingCards websData={websDataState} />
      </div>
    </Layout>
  )
}

export default ranking
