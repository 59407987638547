module.exports = [
  {
    id: 1,
    category: 'ASX 100',
    description: 'We rank the websites of ASX 100 companies based on their web performance',
    slug: '/rankings/asx100'
  },
  {
    id: 2,
    category: 'S&P 500',
    description:
      'We rank the websites of 500 largest public companies in the USA based on their web performance',
    slug: '/rankings/sp500'
  },
  {
    id: 3,
    category: 'FTSE 100',
    description:
      'We rank the websites of 100 largest public companies in the UK based on their web performance',
    body: '<b>This</b> is a test',
    slug: '/rankings/ft100'
  },
  {
    id: 4,
    category: 'Peru Universities',
    description:
      'We rank the websites of the top 100 universities in the world based on their web performance',
    body: '<b>This</b> is a test',
    slug: '/rankings/university'
  }
]
