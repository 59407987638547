import React from 'react'

import Card from '../../../shared/components/Card'
import Button from '../../../shared/components/Button'

const RankingCard = ({ web }) => {
  return (
    <Card className="w-full sm:px-2 md:px-4 lg:px-6">
      <div className="my-8 flex flex-col items-center justify-center text-center">
        <h2>{web.category}</h2>
        <p className="text-center my-3">{web.description}</p>
        <div>
          {web.slug && (
            <Button className="mt-1" navigateTo={web.slug} loadingSpinner>
              View More
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}

export default RankingCard
